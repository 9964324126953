let $menu_btn = $('.menu-button');
let $header = $('header');


$menu_btn.click(function() {
$header.toggleClass('menu-is-active');
if($header.hasClass('menu-is-active') == true){
    console.log('that shit true');
    $menu_btn.text('Lukk meny')
}else{
    $menu_btn.text('Meny')
}
})

